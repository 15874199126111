// Import styles
import './Scss/Main.scss';

// Import libraries
import './Js/Libraries';
import './Js/MainMenu';
import './Js/LangMenu';
import './Js/Animate';
import './Js/Scroll';
import './Js/Collapse';
import './Js/Rating';
import './Js/Tooltip';
import './Js/HeaderVideo';
import './Scss/20_ContentElements/StatisticDoughnut/DoughnutCharts';
import { Finder } from './Scss/20_ContentElements/Finder/Finder';
import { SolrSearch } from './Scss/20_ContentElements/Search/SolrSearch';
import './Scss/20_ContentElements/Splide/Splide';
import { Glossar } from './Scss/20_ContentElements/Glossar/Glossar';
// import './Scss/20_ContentElements/Search/InvinityList';
import './Scss/20_ContentElements/Focusteaser/FocusteaserCarousel';
import './Scss/20_ContentElements/ModalChangePlatform/ModalChangePlatform';
import './Scss/20_ContentElements/Popup/Popup';
import DownloadWarning from './Scss/20_ContentElements/DownloadContent/DownloadWarning';

import './Js/Multiselect';
import './Scss/20_ContentElements/Infographic/InfographicProgress';
import './Scss/20_ContentElements/Infographic/InfographicLine';
import './Scss/20_ContentElements/Infographic/InfographicModal';
import {initSlider} from "./Scss/20_ContentElements/Splide/Splide";
window.addEventListener('DOMContentLoaded', (event) => {
  for (const el of document.getElementsByClassName('tx_solrscm_finder')) {
    new Finder(el);
  }
  // window.finderSearch = new Finder();
  window.solrSearch = new SolrSearch();

  for (const el of document.querySelectorAll('[data-sticky-top]')) {
    new Glossar(el);
  }

  new DownloadWarning();

});

// init mmenu light
// $(function () {
// 	const node = document.querySelector("#mainMenuMobile");
// 	const menu = new MmenuLight(node,
// 		"(max-width: 991px)"
// 	);

// 	const navigator = menu.navigation({
// 		// options
// 	});

// 	const drawer = menu.offcanvas({
// 		position: 'right'
// 	});

// 	document.querySelector('a[href="#mainMenuMobile"]').addEventListener('click', (evnt) => {
// 		evnt.preventDefault();
// 		evnt.stopPropagation();
// 		drawer.open();
// 	});

// 	// move the metaMenu and language menu inside the mmenu
// 	if ($(window).width() < 991) {
// 		var $metaMenuMenu, $metaMenuBottom, $languageMenu, $menuBottom;

// 		$metaMenuMenu = $(".metaMenu--mobileMenu").clone();
// 		$metaMenuBottom = $(".metaMenu--mobileBottom").clone();
// 		$languageMenu = $(".langSwitchMobile").clone();
// 		$menuBottom = $("<nav class='menuBottom'></nav>");

// 		$metaMenuMenu.prependTo("#mainMenuMobile > .mm-spn--open");

// 		$menuBottom.appendTo(".mm-ocd__content");
// 		$metaMenuBottom.appendTo(".menuBottom");
// 		$languageMenu.appendTo(".menuBottom");
// 	}
// });

// anchor links
// function initAnchor() {
//   let id;

//   function scrolltoAnchor(id) {
//     console.log('scrolltoAnchor(id)', id);
//     // if (id !== '' && $(id).length !== 0) {
//     //   $('html, body').stop(true, true).animate({
//     //     scrollTop: $(id).offset().top - 80,
//     //   }, 1000);
//     // }
//   }

//   const scrollto = document.querySelectorAll('a');
//   scrollto.forEach((el) => {
//     console.log(el.hash);
//     if (el.hash) {
//       el.addEventListener('click', () => {
//         scrolltoAnchor(el.hash);
//       });
//     }
//   });

//   $(window).scrollTop(0);

//   id = $(location).attr('hash');
//   scrolltoAnchor(id);
// }

// initAnchor();

(function (document, history, location) {
  const HISTORY_SUPPORT = !!(history && history.pushState);

  const anchorScrolls = {
    ANCHOR_REGEX: /^#[^ ]+$/,
    OFFSET_HEIGHT_PX: 200,

    /**
     * Establish events, and fix initial scroll position if a hash is provided.
     */
    init() {
      this.scrollToCurrent();
      window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
      document.body.addEventListener('click', this.delegateAnchors.bind(this));

      const self = this;
      const collapseElementList = [].slice.call(document.querySelectorAll('.accordion-collapse'));

      const collapseList = collapseElementList.map((collapseEl) => {
        collapseEl.addEventListener('shown.bs.collapse', () => {
          console.log('isInViewport -> ', collapseEl.getBoundingClientRect().top);
          if (collapseEl.getBoundingClientRect().top < self.OFFSET_HEIGHT_PX) {
            self.scrollIfAnchor('', true, collapseEl.getAttribute('id'));
          }
        });
      });
    },

    /**
     * Return the offset amount to deduct from the normal scroll position.
     * Modify as appropriate to allow for dynamic calculations
     */
    getFixedOffset() {
      return this.OFFSET_HEIGHT_PX;
    },

    /**
     * If the provided href is an anchor which resolves to an element on the
     * page, scroll to it.
     * @param  {String} href
     * @return {Boolean} - Was the href an anchor.
     */
    scrollIfAnchor(href, pushToHistory, anchorID) {
      let match; let rect; let
        anchorOffset;

      if (!this.ANCHOR_REGEX.test(href) && !anchorID) {
        return false;
      }

      const id = href.slice(1) || anchorID;
      match = document.getElementById(id);

      if (match) {
        rect = match.getBoundingClientRect();
        anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
        window.scrollTo(window.pageXOffset, anchorOffset);

        // Add the state to history as-per normal anchor links
        if (HISTORY_SUPPORT && pushToHistory) {
          history.pushState({}, document.title, location.pathname + href);
        }
      }

      return !!match;
    },

    /**
     * Attempt to scroll to the current location's hash.
     */
    scrollToCurrent() {
      this.scrollIfAnchor(window.location.hash);
    },

    /**
     * If the click event's target was an anchor, fix the scroll position.
     */
    delegateAnchors(e) {
      const elem = e.target;
      if (
        elem.nodeName === 'A'
        && this.scrollIfAnchor(elem.getAttribute('href'), true)
      ) {
        e.preventDefault();
      }
    },
  };

  window.addEventListener(
    'DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls),
  );
}(window.document, window.history, window.location));

// TODO: move to extra file
// init upload

const uploaditems = document.querySelectorAll('.js-upload-input');

for (let i = 0; i < uploaditems.length; i++) {
  const button = uploaditems[i];
  console.log(button, i);
  const label = button.nextElementSibling.querySelector('.file-chosen');
  console.log(button, i, label);

  button.addEventListener('change', function () {
    label.innerHTML = this.files[0].name;
  });
}

/** ************ PASSWORD TOGGLE  ************* */
const tooglePasswordButton = document.querySelector('#togglePassword');
if (tooglePasswordButton) {
  tooglePasswordButton.addEventListener('click', (e) => {
    const passwordInput = document.querySelector('[name="pass"]');
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
    } else {
      passwordInput.type = 'password';
    }
  });
}

/** ************ NEWSLETTER SUBSCRIBE  ************* */
// make input ids unique
const forms = document.querySelectorAll('[data-form-id]');
forms.forEach((el) => {
  const formId = el.getAttribute('data-form-id');
  const ids = el.querySelectorAll('[id]');
  ids.forEach((e) => {
    const val = e.getAttribute('id');
    e.setAttribute('id', `${val}-${formId}`);
  });
  const fors = el.querySelectorAll('[for]');
  fors.forEach((e) => {
    const val = e.getAttribute('for');
    e.setAttribute('for', `${val}-${formId}`);
  });
});
// Show footer image instead of subscription form
const successSubscribe = document.querySelectorAll('[data-newsletter-subscribe-success]');
if (successSubscribe.length > 0) {
  const footerEl = document.querySelector('[data-footer-newsletter-subscribe]');
  footerEl.setAttribute('data-footer-newsletter-subscribe', 'success');
}

/* ************ OPEN COOKIE SETTINGS  ************* */
const cookieLinks = document.querySelectorAll('.link-cookie-settings');
cookieLinks.forEach((el) => {
  console.log(el);
  el.addEventListener('click', (e) => {
    e.stopPropagation();
    e.preventDefault();
    UC_UI.showSecondLayer();
  });
});

/* ************ enamble browser zoom  ************* */
function reportWindowSize() {
  if (window.outerWidth > 0) {
    document.body.style.setProperty('--viewport-width', `${window.outerWidth}px`);
    document.body.style.setProperty('--viewport-height', `${window.outerHeight}px`);
  } else {
    setTimeout(() => {
      reportWindowSize();
    }, 500);
  }
}
window.onresize = reportWindowSize;
reportWindowSize();

// ios video preview fix
const userAgent = navigator.userAgent || navigator.vendor || window.opera;
if (userAgent.includes('iPhone')) {
  document.querySelectorAll('video').forEach((video) => {
    video.load();
  });
}

document.addEventListener('DOMContentLoaded', () => {

  // deal with sliders that arent loaded via ajax
  // recipe cards are always randomized and loaded via ajax, therefore they are missing here
  const cheeseSplideEls = document.querySelectorAll('.cheeseSplide');
  const cheeseBrandSplideEls = document.querySelectorAll('.cheeseBrandSplide');
  const storySplideEls = document.querySelectorAll('.storySplide');
  for (let i = 0; i < cheeseSplideEls.length; i++) {
    initSlider(cheeseSplideEls[i], optionsCheese);
  }
  for (let i = 0; i < cheeseBrandSplideEls.length; i++) {
    initSlider(cheeseBrandSplideEls[i], optionsCheese);
  }
  for (let i = 0; i < storySplideEls.length; i++) {
    initSlider(storySplideEls[i], optionsReciep);
  }

  // deal with sliders that are loaded via ajax
  const elements = document.querySelectorAll('[data-ajax-url]');
  elements.forEach(element => {
    const url = element.getAttribute('data-ajax-url');
    let id = element.getAttribute('id');
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(data => {
        element.outerHTML = data;
        const newElement = document.getElementById(id);
        // init slider
        const cardAjaxSplideEls = newElement.querySelectorAll('.cheeseBrandSplide, .cheeseSplide');
        for (let i = 0; i < cardAjaxSplideEls.length; i++) {
          initSlider(cardAjaxSplideEls[i], optionsCheese);
        }
        const cardRecipeAjaxSplideEls = newElement.querySelectorAll('.recipeSplide');
        for (let i = 0; i < cardRecipeAjaxSplideEls.length; i++) {
          initSlider(cardRecipeAjaxSplideEls[i], optionsReciep);
        }
        // add fade in animation to be triggered when item is in view
        const animateElements = newElement.querySelectorAll('.js-animate-trigger');
        animateElements.forEach(animateElement => {
          lazyBackgroundObserver.observe(animateElement);
        });
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  });
});

